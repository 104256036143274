import CTO from "@/components/Common/CTO";
import Link from "next/link";

export default function Custom404() {
  return (
    <>
      <div className="error-area ptb-100">
        <div className="container">
          <div className="error-content">
            <h3 className="main-title">Lỗi 404 : Trang Không Tìm Thấy</h3>
            <p className="dark:tw-text-white !tw-mb-6">
              Trang bạn đang tìm có thể đã bị xóa, đổi tên hoặc tạm thời không có sẵn.
            </p>
            <Link
              href="/"
              className="tw-w-fit tw-bg-red tw-text-white hover:tw-bg-red hover:tw-text-white dark:tw-bg-_red-700 hover:dark:tw-bg-white dark:tw-text-white hover:dark:tw-text-_red-700 tw-py-2 tw-px-4 tw-rounded tw-text-center tw-font-bold tw-uppercase tw-text-sm"
            >
              Trở Về Trang Chủ
            </Link>
          </div>
        </div>
      </div>
      <CTO />
    </>
  );
}
