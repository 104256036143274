import baseApiUrl from "@/utils/baseApiUrl";
import axios from "axios";
import Image from "next/image";
import React from "react";

const CTO = () => {
   const [cto, setCto] = React.useState();
   React.useEffect(() => {
      const getCto = async () => {
         const response = await axios.get(`${baseApiUrl}/api/cto?populate=*`);
         setCto(response.data);
         // console.log(response.data);
      };
      getCto();
   }, []);
   return (
      <>
         {cto && (
            <div className="background-theme-even pt-100 pb-75">
               <div className="container">
                  <div className="row justify-content-center">
                     {cto.data.attributes.ctoInfo.map((info) => (
                        <div className="col-lg-3 tw-content-center col-md-6" key={info.id}>
                           <div className="overview-card">
                              <h3 className="!tw-text-_red-700">{info.title}</h3>
                              <span className="!tw-text-black-900 dark:!tw-text-white">{info.text}</span>
                              <div className="overview-shape tw-z-10">
                                 <Image
                                    src="/images/overview/overview-shape.png"
                                    alt="image"
                                    width={40}
                                    height={40}
                                 />
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         )}
      </>
   );
};

export default CTO;
